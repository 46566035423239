import React from "react";
import Divider from "./Divider";
import styled from "styled-components";

const TitleContainer = styled.div`
  margin-bottom: 3.5rem;
`;
const Container = styled.div`
  height: 20vh;
  padding: 0 45px;
`;

const Spacer = styled.div`
  height: 1px;
  background-color: #141414;
`;

const WorkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ArticleContainer = styled.div`
  width: calc(100% / 12 * 4);
  max-width: calc(100% / 12 * 4);
  flex: 0 0 auto;
  flex-basis: calc(100% / 12 * 4);
`;

const ArticleTitle = styled.div`
  font-family: "Elza-Bold";
  font-size: 2rem;
  line-height: 1.5rem;
  font-size: 1.125rem;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
`;

const ArticleDescription = styled.div`
  font-family: "Elza-Regular";
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 1.25rem;
  padding-right: 2.5rem;
`;

const RoleContainer = styled.div`
  width: calc(100% / 12 * 2);
  max-width: calc(100% / 12 * 2);
  flex: 0 0 auto;
  flex-basis: calc(100% / 12 * 2);
  padding: 0 1.875rem;
`;

const RoleDescription = styled.div`
  font-family: "Elza-Regular";
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 1.25rem;
`;

const L = styled.text`
  font-family: "Helvetica";
  font-weight: 700;
`;

const Work = () => {
  return (
    <>
      <TitleContainer>
        <Divider section="work" />
      </TitleContainer>

      <Container>
        <Spacer />
        <WorkContainer>
          <ArticleContainer>
            <ArticleTitle>
              Langroid<L>:</L> Harness LLMs with Multi-Agent Programming
            </ArticleTitle>
            <ArticleDescription>
              Google is a multinational technology company that specializes in
              Internet-related services and products, which include online
              advertising technologies, a search engine, cloud computing,
              software, and hardware.
            </ArticleDescription>
          </ArticleContainer>
          <RoleContainer>
            <ArticleTitle>Role</ArticleTitle>
            <RoleDescription>
              Software Engineer
              <br />
              Open-source Contributor
              <br />
              Natural Language to SQL
            </RoleDescription>
          </RoleContainer>
          <ArticleContainer>
            <ArticleTitle>Press</ArticleTitle>
            <ArticleDescription>
              Github Pages<L>↩</L>
              <br />
              Linkedin<L>↩</L>
            </ArticleDescription>
          </ArticleContainer>
        </WorkContainer>
      </Container>
    </>
  );
};

export default Work;
