import React from "react";
import styled from "styled-components";

const Container = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  background-color: white;
  z-index: 2;
`;

const Left = styled.div`
  height: 100%;
  margin-left: 2.5rem;
`;

const Right = styled.div`
  height: 100%;
  margin-right: 2.5rem;
`;

const Name = styled.a`
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 45px;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit; /* Inherit the color from parent */
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: flex-end;
  line-height: 18px;
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  list-style-position: outside;
  text-align: right;
  vertical-align: baseline;
`;

const MenuItem = styled.li``;

const MenuLink = styled.a`
  font-size: 13px;
  font-family: "Elza-Regular", sans-serif;
  letter-spacing: 2px;
  line-height: 45px;
  text-decoration: none;
  color: inherit;
  padding: 0 32px;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit; /* Inherit the color from parent */
  }
`;

function Header() {
  return (
    <>
      <Container>
        <Left>
          <Name href="/">RITHWIK BABU</Name>
        </Left>

        <Right>
          <Menu>
            <MenuItem>
              <MenuLink href="#work">WORK</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="#leadership">LEADERSHIP</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="#strategy">STRATEGY</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="#writing">WRITING</MenuLink>
            </MenuItem>
          </Menu>
        </Right>
      </Container>
    </>
  );
}

export default Header;
