import React from "react";
import Divider from "./Divider";
import styled from "styled-components";

const TitleContainer = styled.div`
  padding-top: 6rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
`;

const Left = styled.div`
  width: 50%;
`;

const LeftContainer = styled.div`
  margin-right: 10vw;
  top: 48px;
  position: -webkit-sticky;
  position: sticky;
  padding: 0 1rem;
  padding-bottom: 5rem;
  padding-top: 9rem;
`;

const LeftText = styled.div`
  letter-spacing: 0.3px;
  font-family: "Elza-Regular";
  font-size: 2rem;
  padding: 0 1.5rem 1.5rem 0;
`;

const CallButton = styled.div`
  border-radius: 75px;
  border: 1px solid #000;
  padding: 18px 28px;
  font-family: "Elza-Regular";
  letter-spacing: 0.3px;
  font-size: 20px;
  line-height: 24px;
  display: inline-block;

  &:hover {
    color: #fff;
    background-color: #131313;
  }
`;

const Right = styled.div`
  flex: 1;
  overflow: auto;
  width: 50%;
  flex-direction: column;
  padding-top: 9rem;
`;

const RightTop = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 350px;
`;

const RightMetricContainer = styled.div`
  width: 50%;
  margin: 0 15px;

  display: flex;
  flex-direction: column;
`;

const Metric = styled.div`
  font-family: "Elza-Regular";
  font-size: 7rem;
  letter-spacing: -0.175rem;
`;

const MetricTitle = styled.div`
  font-family: "Elza-Semibold";
  font-size: 1.125rem;
  padding-right: 5rem;
  padding-bottom: 0.5rem;
  margin-top: -0.5rem;
`;

const MetricSubtitle = styled.div`
  font-family: "Elza-Regular";
  letter-spacing: 0.3px;
  font-size: 15px;
  line-height: 22px;
  padding-right: 5rem;
`;

const RightBottom = styled.div`
  padding: 0 15px;
  padding-bottom: 6rem;
`;

const ListTitle = styled.div`
  font-family: "Elza-Bold";
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding-bottom: 0.5rem;
  letter-spacing: 0.3px;
`;

const LI = styled.div`
  font-family: "Helvetica";
  letter-spacing: 0.3px;
  font-size: 15px;
  line-height: 23px;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;

  /* Adding the rainbow border */
  border-image-source: radial-gradient(
    circle farthest-corner at 100% 0,
    #ffaccf 28%,
    #5cb6f8 51%,
    #ba75e0 87%,
    #dd5183 96%
  );
  border-image-slice: 1;
  border-bottom: 1px solid;
`;

const L = styled.text`
  font-family: "Helvetica";
`;

const Leadership = () => {
  return (
    <>
      <TitleContainer>
        <Divider section="leadership" />
      </TitleContainer>

      <Container>
        <Left>
          <LeftContainer>
            <LeftText>
              Shanpnig ptucdors si garet, tub twah I dnif neve erom nmeangiluf
              si gipelnh teohrs rgwo. I fofre orb-pono mhprseonit ot wcsmoenre
              ni eht ldeif ytnrig ot teg rhtei rifts dngieesr ojb, dna ehost
              whcinsgit crerase otni AP.
            </LeftText>
            <CallButton href="/call">
              Schedule a call <L>↗︎</L>
            </CallButton>
          </LeftContainer>
        </Left>
        <Right>
          <RightTop>
            <RightMetricContainer>
              <Metric>
                300<L>+</L>
              </Metric>
              <MetricTitle>Cillum mnsrteohip sneosiss</MetricTitle>
              <MetricSubtitle>
                Mlod nokeyl 1<L>:</L>1<L>'</L>s wtih dneiesgrs to hlep edtcuae,
                giude and aessss dseign pocress, cmoumniaciton and eotuxecin.
              </MetricSubtitle>
            </RightMetricContainer>
            <RightMetricContainer>
              <Metric>500</Metric>
              <MetricTitle>Tdearni no DIE ni isegnD</MetricTitle>
              <MetricSubtitle>
                nTdiera dna dobnaore 500<L>+</L> wen hirse no yseviDrit, uEyqti
                dna nIosculni ni gDeisn cpoist sa trap fo taeM nesigD pmaC
                ngdOnairbo sseions
              </MetricSubtitle>
            </RightMetricContainer>
          </RightTop>
          <RightBottom>
            <ListTitle>Highlights</ListTitle>
            <LI>Lorem ipsum dolor sit amet, consectetur adipiscing elit</LI>
            <LI>
              Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </LI>
            <LI>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            </LI>
            <LI>
              Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
            </LI>
            <LI>
              Reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur
            </LI>
            <LI>Lorem ipsum dolor sit amet, consectetur adipiscing elit</LI>
            <LI>
              Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </LI>
            <LI>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            </LI>
            <LI>
              Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
            </LI>
            <LI>
              Reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur
            </LI>
          </RightBottom>
        </Right>
      </Container>
    </>
  );
};

export default Leadership;
