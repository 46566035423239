import React from "react";
import Divider from "./Divider";
import styled from "styled-components";

const TitleContainer = styled.div`
  padding-top: 3rem;
  background-color: #192e2b;
  color: white;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: #192e2b;
`;

const Left = styled.div`
  width: 50%;
`;

const LeftContainer = styled.div`
  margin-right: 10vw;
  top: 48px;
  position: -webkit-sticky;
  position: sticky;
  padding: 0 1rem;
  padding-bottom: 5rem;
  padding-top: 9rem;
`;

const LeftText = styled.div`
  letter-spacing: 0.3px;
  font-family: "Elza-Regular";
  font-size: 2rem;
  padding: 0 1.5rem 1.5rem 0;
  color: #ffd300;
`;

const SubLeftText = styled.div`
  color: #bdc5c4;
  font-size: 15px;
`;

const Right = styled.div`
  flex: 1;
  overflow: auto;
  width: 50%;
  flex-direction: column;
  padding-top: 9rem;
`;

const RightTop = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 350px;
`;

const WIP = styled.text`
  color: red;
  background-color: pink;
  font-size: 3rem;
  height: 1%;
`;

const RightBottom = styled.div`
  padding: 0 15px;
  padding-bottom: 6rem;
`;

const L = styled.text`
  font-family: "Helvetica";
`;

const Strategy = () => {
  return (
    <>
      <TitleContainer>
        <Divider section="strategy" />
      </TitleContainer>

      <Container>
        <Left>
          <LeftContainer>
            <LeftText>My strategy is still a work in progress.</LeftText>
            <SubLeftText>Stay tuned.</SubLeftText>
          </LeftContainer>
        </Left>
        <Right>
          <RightTop>
            <WIP>Coming soon.</WIP>
          </RightTop>
        </Right>
      </Container>
    </>
  );
};

export default Strategy;
