import "./App.css";
import Divider from "./components/Divider";
import Header from "./components/Header";
import Home from "./components/Home";
import styled from "styled-components";
import Leadership from "./components/Leadership";
import Work from "./components/Work";
import Strategy from "./components/Strategy";
import Writing from "./components/Writing";
import More from "./components/More";

const Container = styled.div`
`;

const Sections = styled.div`
  
`;

function App() {
  return (
    <Container>
      <Header />
      <Sections>
        <Home />
        <Work />
        <Leadership />
        <Strategy />
        <Writing />
        <More />
      </Sections>
    </Container>
  );
}

export default App;
