import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 2rem;
  background-color: #faf3ea;
`;

const Left = styled.div`
  width: 50%;
`;

const Header = styled.p`
  font-size: 18px;
  font-family: "Elza-Semibold";
  margin: 0;
  padding: 12px 0 8px;
`;

const Subtext = styled.div`
  font-family: "Elza-Regular";
  font-size: 15px;
  padding: 0 40px 24px 0;
  letter-spacing: 0.3px;
  line-height: 20px;
`;

const Download = styled.a`
  text-decoration: underline;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit; /* Inherit the color from parent */
  }
`;

const Right = styled.div`
  width: 50%;
`;

const L = styled.text`
  font-family: "Helvetica";
`;

const Writing = () => {
  return (
    <>
      <Container>
        <Left>
          <Header id="about me">About me</Header>
          <Subtext>
            Who is actually reading this.
          </Subtext>
          <Header>Contact</Header>
          <Subtext>
            rithwikbabu2020<L>@</L>gmail.com
            <br />
            Madison, WI
            <br />
            <br />
            <Download href="" download>
              Download Resume <L>(</L>PDF<L>)</L>
            </Download>
          </Subtext>
        </Left>
        <Right></Right>
      </Container>
    </>
  );
};

export default Writing;
