import React from "react";
import styled from "styled-components";

const OuterContainer = styled.div`
  padding-top: 9.333rem;
  padding-bottom: 4rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2.5rem;
`;

const InnerContainer = styled.div`
  width: 50%;
`;

const Header = styled.p`
  font-size: 18px;
  font-family: "Elza-Regular";
  letter-spacing: 0.3px;
  line-height: 24px;
  padding: 24px 0;
  padding-bottom: 24px;
  margin: 0;
`;

const SubHeader = styled.p`
  padding: 0 28px 24px 0;
  font-size: 32px;
  font-family: "Elza-Regular";
  line-height: 40px;
  margin: 0;
`;

const LinkHeader = styled.div``;

const Link = styled.a`
  line-height: 28px;
  letter-spacing: 0.3px;
  font-size: 18px;
  font-family: "Elza-Regular", "sans-serif", sans-serif;

  color: inherit;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit; /* Inherit the color from parent */
  }
`;

const Da = styled.text`
  font-family: sans-serif;

  &::before {
    content: "↓";
    display: inline;
  }
`;

const L = styled.text`
  font-family: sans-serif;
`;

const Construction = styled.div`
  background-color: pink;
  color: red;
  font-size: 60px;
`;

const Home = () => {
  return (
    <OuterContainer>
      <Container>
        <InnerContainer>
          <Header>
            Hi, I<L>'</L>m Rithwik Babu,
            <br />
            Computer Science and Statistics at UW-Madison
          </Header>
          <SubHeader>
            Currently in school. Focused on quantitative software development
            and data analytics. Strong background in React, Node and Amazon Web
            Services. Previously, developed LLM-based applications at Infogito
            and Langroid.
          </SubHeader>
          <LinkHeader>
            <Link href="#work">
              Work <Da />
            </Link>
            <br />
            <Link href="#Education">
              Education <Da />
            </Link>
            <br />
            <Link href="#leadership">
              Leadership <Da />
            </Link>
            <br />
            <Link href="#strategy">
              Strategy <Da />
            </Link>
            <br />
            <Link href="#about me">
              About me <Da />
            </Link>
            <br />
            <Link href="#more">
              There<L>'</L>s more <Da />
            </Link>
          </LinkHeader>
        </InnerContainer>
        <InnerContainer>
          <Construction>Page under construction.</Construction>
        </InnerContainer>
      </Container>
    </OuterContainer>
  );
};

export default Home;
