import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 2rem;
  background-color: #000000;
`;

const Left = styled.div`
  width: 50%;
`;

const Header = styled.p`
  font-size: 18px;
  font-family: "Elza-Semibold";
  margin: 0;
  padding: 12px 0 8px;
  color: white;
`;

const Subtext = styled.div`
  font-family: "Elza-Regular";
  font-size: 15px;
  padding: 0 40px 24px 0;
  letter-spacing: 0.3px;
  line-height: 20px;
  color: #A5A5A5;
`;

const Download = styled.a`
  text-decoration: underline;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit; /* Inherit the color from parent */
  }
`;

const Right = styled.div`
  width: 50%;
`;

const L = styled.text`
  font-family: "Helvetica";
`;

const More = () => {
  return (
    <>
      <Container>
        <Left>
          <Header id="more">
            There<L>'</L>s more
          </Header>
          <Subtext>
            There<L>'</L>s a lot more, in case you are interested in taking a
            look at in-depth case studies of my previous projects.
          </Subtext>
        </Left>
        <Right></Right>
      </Container>
    </>
  );
};

export default More;
