import React from 'react';
import styled from "styled-components";

const Container = styled.div`
    font-family: "Gallient";
    font-size: 14vw;
    padding: 0 45px;
    letter-spacing: -0.3vw;
    margin-top: 1vw;
    padding-bottom: 1vw;
    line-height: 90%;
    text-align: center;
    padding-top: 4rem;
`;


const Divider = ({ section }) => {
    return (
        <Container id={section}>
            {section}
        </Container>
    );
};

export default Divider;
